import { disableExternalRasterLayers, enableExternalRasterLayers, disableVisibilities, enableVisibilities } from '@/api/pmexternalrasterlayers';

const disableExternalRasterLayer = {
	id: 'disableExternalRasterLayer',
	selectionType: 'multiple',
	label: 'pmexternalrasterlayers.actions.disableExternalRasterLayer',
	functionality: 'UPDATE_PMEXTERNALRASTERLAYERS',
	checkAvailability: function (externalrasterlayer) {
		return externalrasterlayer && externalrasterlayer.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmexternalrasterlayers.actions.disableExternalRasterLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmexternalrasterlayersid: registries[i].pmexternalrasterlayersid
			});
		}

		const data = await disableExternalRasterLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableExternalRasterLayer = {
	id: 'enableExternalRasterLayer',
	selectionType: 'multiple',
	label: 'pmexternalrasterlayers.actions.enableExternalRasterLayer',
	functionality: 'UPDATE_PMEXTERNALRASTERLAYERS',
	checkAvailability: function (externalrasterlayer) {
		return externalrasterlayer && externalrasterlayer.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmexternalrasterlayers.actions.enableExternalRasterLayer');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmexternalrasterlayersid: registries[i].pmexternalrasterlayersid
			});
		}

		const data = await enableExternalRasterLayers(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// New actions - Visibility enable/disable
const disableVisibility = {
	id: 'disableVisibility',
	selectionType: 'multiple',
	label: 'pmexternalrasterlayers.actions.disableVisibility',
	functionality: 'UPDATE_PMEXTERNALRASTERLAYERS',
	checkAvailability: function (externalrasterlayer) {
		return externalrasterlayer && externalrasterlayer.visibility === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmexternalrasterlayers.actions.disableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmexternalrasterlayersid: registries[i].pmexternalrasterlayersid
			});
		}

		const data = await disableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVisibility = {
	id: 'enableVisibility',
	selectionType: 'multiple',
	label: 'pmexternalrasterlayers.actions.enableVisibility',
	functionality: 'UPDATE_PMEXTERNALRASTERLAYERS',
	checkAvailability: function (externalrasterlayer) {
		return externalrasterlayer && externalrasterlayer.visibility === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmexternalrasterlayers.actions.enableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmexternalrasterlayersid: registries[i].pmexternalrasterlayersid
			});
		}

		const data = await enableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableExternalRasterLayer, enableExternalRasterLayer, disableVisibility, enableVisibility]
};
